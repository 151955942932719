<script setup>
import {defineProps, ref, watch} from "vue";

const props = defineProps({
	currentPage: {
		type: Number,
		required: true
	}
})
const activePage = ref(props.currentPage)
if(activePage.value === -1) {
	activePage.value = 0;
}
const pageTitle = ['之初', '共度', '久長時', '君心我心']

watch(
	() => props.currentPage,
	(newValue) => {
		if(activePage.value === -1) {//規一化
			activePage.value = 0;
		}
		activePage.value = newValue;
	}
);

</script>

<template>
	<transition name="fade">
		<div class="container">
			<div class="inner">
				<div class="inner-left">
					<div v-for="(item, index) in pageTitle" :key="item" class="circle" :class="{ active: index === activePage }">
						<svg width="10" height="10" class="svg-item-circle">
							<circle cx="5" cy="5" r="4" fill="black"/>
						</svg>
						<svg width="5" height="30" v-if="item!==pageTitle.at(pageTitle.length-1)" class="svg-item-rect">
							<rect x="1" width="3" height="30" rx="2"/>
						</svg>
					</div>
				</div>
				<div class="inner-right">
					<transition name="content-fade" mode="out-in">
					<span :key="pageTitle[activePage]" class="page-title">
						{{ pageTitle[activePage] }}
					</span>
					</transition>
				</div>
			</div>

		</div>
	</transition>
</template>

<style scoped>
.container {
	height: 200px;
	width: fit-content;
	padding: 10px;
	transition: all 0.5s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(207, 207, 207, 0.6) 3px 3px 20px;
	border:5px double darkgray;
	background-color: rgba(255, 255, 255, 0.9);
}

.inner {
	display: flex;
	flex-direction: row;
}

.inner-left {
	height: fit-content;
	width: fit-content;
	/*background-color: pink;*/
	border-right: #cfcfcf 2px solid;
	transition: all 0.5s ease-in-out;
	padding: 10px 15px 10px 10px;
	display: flex;
	flex-direction: column;
}

.inner-right {
	width: fit-content;
}

.circle {
	max-width: 100%;
	/*background-color: yellow;*/
	display: flex;
	align-items: center;
	flex-direction: column;
	transition: transform 0.3s ease;
	transform: scale(1.0);
}

.active .svg-item-circle {
	transform: scale(1.85) translateY(-1.5px);
}

.active .svg-item-rect {

}

.svg-item-circle {
	transition: transform 3s cubic-bezier(0.3, 0.1, 0.3, 0.9);
	padding-top: 6px;
}

.svg-item-rect {
	transition: transform 1.7s cubic-bezier(0.3, 0.1, 0.3, 0.9);
	padding-top: 6px;
}

.page-title {
	writing-mode: vertical-rl;
	text-orientation: upright;
	min-width: 48px;
	padding-left: 5px;
	padding-top: 10px;
	font-size: 36px;
	font-family: "Hina Mincho", serif;
}

.content-fade-enter-active, .content-fade-leave-active {
	transition: opacity 1.7s ease-in-out;
}

.content-fade-enter-from, .content-fade-leave-to {
	opacity: 0;
}

.fade-enter-active{
	animation: 1s fadeIn 0s forwards;
}

.fade-leave-active {
	animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		scale: 1.2;
	}
	100% {
		opacity: 1;
		scale: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@media screen and (max-height: 400px) {
	.container {
		display: none;
	}
}

@media screen and (min-height: 800px) {
	.container {
		margin-top: 100px;
	}
}

@media screen and (min-width: 650px) {
	.container {
		margin-right: 5%;
	}
}

@media screen and (max-width: 300px) {
	.container {
		display: none;
	}
}
</style>