<script setup>
import {onMounted, ref} from "vue";
import ContinueSwipe from "@/components/utils/ContinueSwipe.vue";
import SideBar from "@/components/utils/SideBar.vue";
import Page_1 from "@/components/pages/Page_1.vue";
import Page_2 from "@/components/pages/Page_2.vue";
import Page_3 from "@/components/pages/Page_3.vue";
import Page_4 from "@/components/pages/Page_4.vue";
import HeadBar from "@/components/utils/HeadBar.vue";
import StartPage from "@/components/pages/StartPage.vue";

// 等待页面完全加载完毕后执行的代码
window.onload = function() {
	checkOrientation();
	checkLoaded();
}

function checkLoaded(){
	const loadingOverlay = document.querySelector('.loading-overlay');
	loadingOverlay.style.display = 'none';
	document.body.style.visibility = 'visible';
	document.body.style.overflow = 'auto';
}

// 在加载期间禁止用户交互事件（如点击和触摸）
document.addEventListener('DOMContentLoaded', function () {
	const preventInteraction = (e) => e.preventDefault();
	document.addEventListener('click', preventInteraction, true);
	document.addEventListener('touchstart', preventInteraction, true);
	window.addEventListener('load', function () {
		document.removeEventListener('click', preventInteraction, true);
		document.removeEventListener('touchstart', preventInteraction, true);
	});
});

function checkOrientation() {
	const overlay = document.querySelector('.landscape-overlay');
	if(overlay!=null){
		if (window.innerHeight < window.innerWidth) {
			overlay.style.display = 'flex';
		} else {
			overlay.style.display = 'none';
		}
	}
}
checkOrientation();

window.addEventListener('resize', checkOrientation);

document.addEventListener('touchmove', function (e) {
	e.preventDefault();
}, {passive: false});

function setViewportHeight() {
	document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
}

function setSectionHeight() {
	const element = document.querySelector('.section-container');
	const height = element.offsetHeight;
	document.documentElement.style.setProperty('--section-height', `${height}px`);
}

function setFooterHeight() {
	const element = document.querySelector('.footnote');
	const height = element.offsetHeight;
	document.documentElement.style.setProperty('--footer-height', `${height}px`);
	return height;
}

function setHeaderHeight() {
	const element = document.querySelector('.head-bar');
	const height = element.offsetHeight;
	document.documentElement.style.setProperty('--header-height', `${height}px`);
	return height
}

function setSectionContainerHeight(){
	const winHeight = window.innerHeight;
	document.documentElement.style.setProperty('--section-container-height', `${winHeight-setHeaderHeight()-setFooterHeight()}px`);
}

let isScrolling = false;
let currentPage = -1; // 当前页码
const continueSwipeState = ref([false, false, false,false]);
const isSidebarVisible = ref(false);
const timerId = ref([]);

function initSidebar() {
	isSidebarVisible.value = true;
	timerId.value.push(setTimeout(() => {
		isSidebarVisible.value = false;
	}, 3000));
}

function hideContinueSwipe(currentPage) {
	continueSwipeState.value[currentPage] = false;
	console.log("隐藏该标签", currentPage)
}

function showContinueSwipe(currentPage) {
	continueSwipeState.value[currentPage] = true;
	console.log("显示该标签", currentPage)
}

function scrollSection(event) {
	if (isScrolling) return;
	isScrolling = true;
	if (event.deltaY > 0) {
		scrollDown()
	} else {
		scrollUp()
	}
	window.removeEventListener('wheel', scrollSection);
	window.removeEventListener('touchmove', handleTouchMove);
	setTimeout(() => {
		isScrolling = false; // 重新允许滚动
		window.addEventListener('wheel', scrollSection);
		window.addEventListener('touchmove', handleTouchMove);
	}, 1700); // 延迟时间
	console.log(continueSwipeState.value)
}

function scrollDown() {
	timerId.value.forEach(timerId => {
		console.log("清除定时器", timerId)
		clearTimeout(timerId);// 防止连续滑动出现问题
	});
	timerId.value = []
	isSidebarVisible.value = true;
	let pages = document.querySelectorAll('.section-item');
	console.log("向下滚动")
	let newPage = Math.min(currentPage + 1, pages.length - 1);
	if (newPage !== currentPage) {
		hideContinueSwipe(currentPage)
		showContinueSwipe(newPage)
	}
	currentPage = newPage;
	pages.forEach(page => {
		page.style.transform = `translateY(calc(var(--section-height, 100vh)*${-currentPage}))`;
	});
	timerId.value.push(setTimeout(() => {
		isSidebarVisible.value = false;// 5秒后隐藏侧边栏
	}, 5000));
}

function scrollUp() {
	timerId.value.forEach(timerId => {
		console.log("清除定时器", timerId)
		clearTimeout(timerId);// 防止连续滑动出现问题
	});
	timerId.value = []
	isSidebarVisible.value = true;
	let pages = document.querySelectorAll('.section-item');
	console.log("向上滚动")
	let newPage = Math.max(currentPage - 1, 0);
	if (newPage !== currentPage) {
		hideContinueSwipe(currentPage)
		showContinueSwipe(newPage)
	}
	currentPage = newPage;
	pages.forEach(page => {
		page.style.transform = `translateY(calc(var(--section-height, 100vh)*${-currentPage}))`;
	});
	timerId.value.push(setTimeout(() => {
		isSidebarVisible.value = false;// 5秒后隐藏侧边栏
	}, 5000));
}

// 处理触摸事件（移动端）
function handleTouchStart(event) {
	this.startY = event.touches[0].clientY; // 记录触摸起始点
}

function handleTouchMove(event) {
	if (isScrolling) return; // 如果正在滚动，直接返回
	const deltaY = this.startY - event.touches[0].clientY;
	// 通过deltaY判断滑动方向
	if (Math.abs(deltaY) > 50) { // 可调节的阈值
		// event.preventDefault(); // 阻止默认滚动
		const direction = deltaY > 0 ? 1 : -1;
		scrollSection({deltaY: direction * 100}); // 模拟滚动事件
	}
}

const isStartPageVisible = ref(true)

function handleTouchStartPage() {
	console.log("关闭初始页")
	isStartPageVisible.value = false;
	init();
	setTimeout(()=>{
		initSidebar();
		showContinueSwipe(0);
		currentPage = 0;
	}, 2500);
}

function init() {
	setViewportHeight();
	setSectionHeight();
	setFooterHeight();
	setHeaderHeight();
	setSectionContainerHeight();
	window.addEventListener('resize', setViewportHeight);
	window.addEventListener('resize', setSectionHeight);
	window.addEventListener('resize', setFooterHeight);
	window.addEventListener('resize', setHeaderHeight);
	window.addEventListener('resize', setSectionContainerHeight);
	window.addEventListener('load', setViewportHeight);
	window.addEventListener('load', setSectionHeight);
	window.addEventListener('load', setFooterHeight);
	window.addEventListener('load', setHeaderHeight);
	window.addEventListener('load', setSectionContainerHeight);
	window.addEventListener('wheel', scrollSection);
	window.addEventListener('touchstart', handleTouchStart);
	window.addEventListener('touchmove', handleTouchMove);
}

onMounted(() => {
	isStartPageVisible.value = true;
});


</script>

<template>
	<div class="loading-overlay">
		<div class="spinner"></div>
		<div class="load-text">首次加載時間較長...望耐心等待</div>
	</div>
	<div class="landscape-overlay">
		<div class="landscape-text">
			<span>請在移動端竪屏模式訪問本網頁，或將瀏覽器窗口拉伸為縱向以開始瀏覽。</span><br>
			<span class="big-landscape-text">本站的地址是 lesliexjy.com</span><br>
			<span>相遇萬維一隅，共同見證「我們的故事」。</span>
		</div>
	</div>
	<div class="page">
			<start-page v-show="isStartPageVisible" class="start-page" @click="handleTouchStartPage"></start-page>
		<div class="head-bar">
			<HeadBar></HeadBar>
		</div>
		<div class="section-container">
			<SideBar class="sidebar" :current-page="currentPage" v-show="isSidebarVisible"></SideBar>
			<div class="section-item">
				<div class="section-content">
					<Page_1 :front="currentPage===0"></Page_1>
				</div>
				<ContinueSwipe class="continue-swipe" @click="scrollDown" :is-visible="continueSwipeState[0]"  continue-string="向下滑動" :has-next="true"/>
			</div>
			<div class="section-item">
				<div class="section-content">
					<Page_2 :front="currentPage===1"></Page_2>
				</div>
				<ContinueSwipe class="continue-swipe" @click="scrollDown" :is-visible="continueSwipeState[1]" continue-string="繼續回顧我們的一週年" :has-next="true"/>
			</div>
			<div class="section-item">
				<div class="section-content">
					<Page_3 :front="currentPage===2"></Page_3>
				</div>
				<ContinueSwipe class="continue-swipe" @click="scrollDown" :is-visible="continueSwipeState[2]" continue-string="還有更多" :has-next="true"/>
			</div>
			<div class="section-item">
				<div class="section-content">
					<Page_4 :front="currentPage===3"></Page_4>
				</div>
				<ContinueSwipe class="continue-swipe" :is-visible="continueSwipeState[3]" continue-string="「我們的故事 永不完結」" :has-next="false"/>
			</div>
		</div>
		<div class="footnote">
			<p class="footnote-para">Made With Love By XingJunyang</p>
			<p class="footnote-para">Powered By <a href="https://cn.vuejs.org" style="text-decoration: none; color: #42b983"><img
				src="../assets/logo.png" class="vue-image" alt="Vue logo"> Vue.js</a></p>
		</div>
	</div>
</template>

<style scoped>
html, body {
	scroll-behavior: smooth;
	height: 100%;
	margin: 0;
	padding: 0;
}

.page {
	height: calc(var(--vh, 1vh) * 100);
	min-height: calc(var(--vh, 1vh) * 100); /* 确保至少占满视口高度 */
	max-height: calc(var(--vh, 1vh) * 100); /* 确保不会超过视口高度 */
	display: flex;
	flex-direction: column;
	overflow: hidden; /* 禁止滚动 */
}

.start-page {
	height: calc(var(--vh, 1vh) * 100);
	min-height: calc(var(--vh, 1vh) * 100); /* 确保至少占满视口高度 */
	max-height: calc(var(--vh, 1vh) * 100); /* 确保不会超过视口高度 */
	width: 100%;
	z-index: 3;
	position: fixed;
}

.head-bar {
	height: 5%;
	width: 100%;
	min-height: 45px;
	background-color: rgba(255, 182, 193, 1);
	border-radius: 0 0 10px 10px;
}

.section-container {
	min-height: var(--section-container-height);
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden; /* 禁止滚动 */
}

.sidebar {
	position: fixed;
	top: calc(var(--header-height, 5%));
	margin-top: 10%;
	right: 30px;
	z-index: 50;

}

.section-item {
	min-height: var(--section-height, 100vh);
	display: flex;
	flex-direction: column;
	justify-items: center;
	justify-content: space-between;
	align-items: center;
	scroll-snap-align: start;
	/*background-color:blue;*/
	transition: transform 1.7s cubic-bezier(0.3, 0.1, 0.3, 0.9); /* 动画效果 */
}

.section-content {
	height: 100%;
	width: 100%;
	justify-self: flex-start;
	/*background-color: #42b983;*/
}

.footnote {
	height: 6vh;
	min-height: 50px;
	font-size: 12px;
	text-align: center;
	padding: 2px;
	width: 100%;
	background: #efefef 40%;
	transition: all 0.5s ease-in-out;
}

.footnote-para {
	margin: 5px;
}

.vue-image {
	height: 12px;
	line-height: 12px;
	top: 2px;
	position: relative;
}

.continue-swipe {
	position: absolute;
	bottom: 0;
	width: fit-content;
	padding-bottom: 20px;
	transition: all 0.5s ease-in-out;
	z-index: 50;
}

@media screen and (max-height: 200px) {
	.footnote {
		display: none;
	}
}

@media screen and (max-height: 400px) and (max-width: 600px) {
	.footnote {
		height: 6vh;
		min-height: 30px;
		font-size: 10px;
		text-align: center;
		padding: 5px;
	}

	.footnote-para {
		margin: 0;
	}
}

@media screen and (max-height: 600px) {
	.sidebar {
		margin-top: 5%
	}
}

@media screen and (min-height: 768px) {
	.head-bar {
		min-height: 5%;
		width: 100%;
	}
}

@media screen and (min-width: 600px) {
	.footnote {
		height: 6vh;
		min-height: 20px;
		font-size: 14px;
		display: flex;
		flex-direction: row;
		align-self: center;
		justify-content: center;
		padding: 5px 5px 10px;
	}

	.footnote-para {
		margin: 5px;
	}

	.vue-image {
		height: 10px;
		top: 0;
	}

	.continue-swipe {
		padding-bottom: 10px;
	}
}

@media screen and (max-width: 400px) {
	.sidebar {
		transform: scale(0.8);
		right: 15px;
		margin-top: 5%;
	}

}

.loading-overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 999; /* 确保遮罩层在最顶层 */
}

.landscape-overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 182, 193, 1); /* 半透明背景 */
	display: none;
	align-items: center;
	justify-content: center;
	z-index: 999; /* 确保遮罩层在最顶层 */
}

.landscape-text{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px;
}

.landscape-text .big-landscape-text{
	font-size: 2em;
	color: rgb(255, 119, 119);
	font-weight: 600;
	font-style: normal;
	text-shadow: 0 0 1px rgb(255, 176, 176);
	font-family: "Hina Mincho", serif;
}

.landscape-text span{
	font-size: 1em;
	color: black;
	font-family: "Hina Mincho", serif;
}

.load-text{
	font-size: 1em;
	color: black;
	font-family: "Hina Mincho", serif;
	margin-top: 20px;
}

/* 简单的加载动画 */
.spinner {
	border: 8px solid #f3f3f3;
	border-top: 8px solid rgb(255, 136, 155);
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
