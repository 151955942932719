<script setup>
import {defineProps, ref, watch} from 'vue'

const props = defineProps({
	front: {
		type: Boolean,
		required: true
	}
})

const isFront = ref(props.front)

watch(
	() => props.front,
	(newValue) => {
		isFront.value = newValue;
		if (isFront.value) {
			console.log('page 3 front')
			handleShowAnimation()
		}
	}
)

const backgroundVisible = ref(false)
const partVisible = ref([false, false, false])

function handleShowAnimation() {
	console.log('show animation')
	setTimeout(() => {
		backgroundVisible.value = true;
		console.log('backgroundVisible: true');
		setTimeout(() => {
			partVisible.value[0] = true;
			console.log('partVisible[0]: true');
			setTimeout(() => {
				partVisible.value[1] = true;
				console.log('partVisible[1]: true');
				setTimeout(() => {
					partVisible.value[2] = true;
					console.log('partVisible[2]: true');
				}, 1000);
			}, 1200);
		}, 1500);
	}, 1500);
}

const memoryVisible1 = ref(true)
const memoryVisible2 = ref(false)

function handleShowMemory() {
	console.log('show memory')
	memoryVisible1.value = false;
	setTimeout(() => {
		memoryVisible2.value = true;
	}, 1700);
}

</script>

<template>
	<div class="container" :class="{active: backgroundVisible}">
		<div class="inner-container">
			<div class="part image-padding" v-show="partVisible[0]">
				<div class="image-container">
					<img src="../../assets/IMG_5218_sq.jpeg" alt="玫瑰花"/>
				</div>
			</div>
			<div class="part" v-show="partVisible[1]">
				<transition name="content-fade">
					<div class="text outer-block" v-show="memoryVisible1">
					<div class="text block" style="padding-top: 5px; padding-bottom: 5px;">
						<span>
							時光釀酒 餘韻成花
						</span>
						<span>
							一年的相守中
						</span>
						<span>
							縱使聚少離多 佳期似夢
						</span>
						<span>
							縱使常常共享十分鐘的時差
						</span>
						<span>
							仍為同一片天空所奔走
						</span>
						<span>
							共跨過時間的荒原
						</span>
					</div>
					<div class="text block" style="padding: 5px;">
						<span>
							花雖好有時枯
						</span>
						<span>
							只有「<span style="font-weight: bolder">愛</span>」不能移
						</span>
					</div>
				</div>
				</transition>
				<transition name="content-fade">
					<div class="text outer-block" v-show="memoryVisible2" >
					<div class="text block" style="padding-top: 5px; padding-bottom: 5px;">
						<span>
							在兩地相望的日子
						</span>
						<span>
							我們依然
						</span>
						<span>
							見面了「<span style="font-weight: bolder">四十</span>」餘次
						</span>
						<span>
							一起走過「<span style="font-weight: bolder">九</span>」個城市
						</span>
						<span>
							最長的一次 在九月
						</span>
						<span>
							通了「<span style="font-weight: bolder">一千零一百一十二分鐘</span>」電話
						</span>
						<span>
							. . . . . .
						</span>
					</div>
				</div>
				</transition>
			</div>
			<div class="part" v-show="partVisible[2]" @click="handleShowMemory">
				<transition name="content-fade">
					<span class="button" v-if="memoryVisible1">更多回憶</span>
				</transition>
			</div>
		</div>
	</div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Yuji+Boku&display=swap');
.container {
	height: 100%;
	max-height: 100%;
	width: 100%;
	background: radial-gradient(ellipse 90% 65% at 50% 45%, rgba(218, 165, 32, 0.65) 0%, rgba(255, 255, 255, 1) 65%);
	display: flex;
	opacity: 0; /* 初始透明度 */
	transition: opacity 1.5s ease /* 3秒的透明度过渡 */
}

.container.active {
	opacity: 1;
}

.inner-container {
	margin: 30px;
	/*background-color: pink;*/
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.part {
	display: flex;
	align-items: center;
	justify-content: center;
	animation: fadeIn 2s forwards 0s;
}

.text {
	font-family: "Hina Mincho", serif;
}

.outer-block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image-container img {
	width: 100%;
	height: 50%;
	object-fit: cover;
	border-radius: 50%;
	border: 2px solid goldenrod;
}

.image-padding {
	padding: 10% 12%;
}

.button{
	padding: 5px 15px;
	margin: 20px 10px 10px;
	border: 2px solid #d3ab45;
	background-color: goldenrod;
	font-family: "Yuji Boku", serif;
	font-weight: 400;
	font-style: normal;
	color: white;
	border-radius: 20px;
	cursor: pointer;
	font-size: 18px;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.content-fade-enter-active, .content-fade-leave-active {
	transition: opacity 1.7s ease-in-out;
}

.content-fade-enter-from, .content-fade-leave-to {
	opacity: 0;
}

@media screen and (max-height: 600px){
	.outer-block {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.block{
		padding-left: 0;
		padding-right: 0;
		font-size: 12px;
	}
}

@media screen and (max-height: 750px) {
	.text {
		font-size: 14px;
	}

	.inner-container {
		margin: 10px;
	}

	.image-padding {
		padding: 7% 25% 5%;
	}

	.button{
		padding: 3px 10px;
		margin: 0 5px 5px;
		border-radius: 15px;
		font-size: 14px;
	}
}

@media screen and (min-width: 800px) {

}
</style>